import React, { Component } from 'react';
import { postData } from './helpers';
import Intro from './components/Intro';
import HowToPlay from './components/HowToPlay';
import Game from './components/Game';
import WellDone from './components/WellDone';
import SaveScore from './components/SaveScore';
import Leaderboard from './components/Leaderboard';
import anime from 'animejs';

class Main extends Component {

	cloud1 = React.createRef();
	cloud2 = React.createRef();
	cloud3 = React.createRef();

	cloud1Dir = 1;
	cloud2Dir = -1;
	cloud3Dir = 1;

	state = {
		step: 1,
		score: 0,
		name: '',
		banned: [],
		leaderboard: [],
		landscape: true,
		scale: 1
	}

	constructor(props) {
		super(props);
		this.moveCloud1 = this.moveCloud1.bind(this);
		this.moveCloud2 = this.moveCloud2.bind(this);
		this.moveCloud3 = this.moveCloud3.bind(this);
		this.resize = this.resize.bind(this)
	}

	componentDidMount() {
		this.moveCloud1();
		this.moveCloud2();
		this.moveCloud3();

		fetch('/banned.json').then(response => {
			return response.json();
		}).then(data => {
			this.setState({ banned: data })
		}).catch(err => {
		});

		/* fetch('/leaderboard.json').then(response => {
			return response.json();
		}).then(data => {
			data.sort((a, b) => parseFloat(b.score) - parseFloat(a.score));
			this.setState({ leaderboard: data });
		}).catch(err => {
		}); */

		this.resize()
		window.addEventListener('resize', this.resize);
	}
	
	resize() {
		let scale = 1
		let vh = window.innerHeight * 0.01
  	document.documentElement.style.setProperty('--vh', `${vh}px`)
		if (window.innerWidth > window.innerHeight) {
			this.setState({landscape: true})
			if (window.innerHeight / window.innerWidth < 0.5625) {
				scale = window.innerHeight/1080
			} else {
				scale = window.innerWidth/1920
			}
		} else {
			this.setState({landscape: false})
			if (window.innerWidth / window.innerHeight < 0.5625) {
				scale = window.innerWidth/1080
			} else {
				scale = window.innerHeight/1920
			}
		}
		if (scale > 1) scale = 1
		if (scale !== this.scale)
			this.setState({scale})
	}

	moveCloud1() {
		this.cloud1Dir *= -1;

		anime({
			targets: this.cloud1.current,
			translateX: 20*this.cloud1Dir,
			complete: this.moveCloud1,
			duration: 6000,
			easing: 'linear'
		});
	}

	moveCloud2() {
		this.cloud2Dir *= -1;

		anime({
			targets: this.cloud2.current,
			translateX: 30*this.cloud2Dir,
			complete: this.moveCloud2,
			duration: 7500,
			easing: 'linear'
		});
	}

	moveCloud3() {
		this.cloud3Dir *= -1;

		anime({
			targets: this.cloud3.current,
			translateX: 40*this.cloud3Dir,
			complete: this.moveCloud3,
			duration: 8500,
			easing: 'linear'
		});
	}

	progress() {
		this.setState({ step: this.state.step + 1 });
	}

	playAgain() {
		this.setState({ step: 3 });	
	}

	updateScore(score) {
		this.setState({ score });
	}

	updateName(name) {
		this.setState({ name });
	}

	saveScore() {

		let bad = false;
		if (this.state.name.length > 2) {
			for (let i=0; i<this.state.banned.length; i++) {
				/*if (this.state.name.toLowerCase().indexOf(this.state.banned[i]) > -1) {
					this.state.name = ''
					bad = true;
					console.log('found')
					break;
				}*/
				if (this.state.banned.indexOf(this.state.name.toLowerCase()) > -1) {
					this.setState({ name: '' })
					bad = true;
					break;
				}
			}
		}
		if (bad) this.setState({ name: '' });
		else {

			if (this.state.name !== '') {
				postData('/save.php', {name: this.state.name, score: this.state.score})
				.then(data => {
					this.progress();
				}).catch(error => {});
			}
		}
	}

	render() {

		const leaderboard = this.state.leaderboard.slice(0, 10);
		let save = true;
		//console.log(this.state.step, this.state.leaderboard.length);
		if (this.state.step === 5 && this.state.leaderboard.length > 9) {
			save = this.state.score > leaderboard[9].score;
		}

		return (
			<div className={`position ${this.state.landscape ? 'landscape' : ''}`}>
				<div className={`container ${this.state.landscape ? 'landscape' : ''}`} style={{transform:`scale(${this.state.scale})`}}>
					<div className="background-layer background-layer--1"></div>
					<div className="background-layer background-layer--2"></div>
					<div className="background-layer background-layer--3"></div>
					<div className="background-layer background-layer--4"></div>
					<div className="background-layer background-layer--5"></div>
					<div className="background-layer background-layer--6"></div>

					<div ref={ this.cloud1 } className="cloud cloud--1"></div>
					<div ref={ this.cloud2 } className="cloud cloud--2"></div>
					<div ref={ this.cloud3 } className="cloud cloud--3"></div>

					{ this.state.step === 1 && <Intro landscape={ this.state.landscape } progress={ () => this.progress() } /> }
					{ this.state.step === 2 && <HowToPlay landscape={ this.state.landscape } progress={ () => this.progress() } /> }
					{ this.state.step === 3 && <Game landscape={ this.state.landscape } progress={ () => this.progress() } updateScore={ (score) => this.updateScore(score) } /> }
					{ this.state.step === 4 && <WellDone landscape={ this.state.landscape } score={ this.state.score } progress={ () => this.progress() } playAgain={ () => this.playAgain() } /> }
					{ this.state.step === 5 && <SaveScore landscape={ this.state.landscape } score={ this.state.score } name={ this.state.name } updateName={ (name) => this.updateName(name) } saveScore={ () => this.saveScore() } topTen={ save } skip={ () => this.progress() } /> }
					{ this.state.step === 6 && <Leaderboard landscape={ this.state.landscape } playAgain={ () => this.playAgain() } score={ this.state.score } leaderboard={ leaderboard } name={ this.state.name }  /> }

					{ this.state.step > 1 && <div className="logo"></div> }
				</div>
			</div>
		);
	}
}

export default Main;