import React, { Component, Fragment } from 'react';
import Timer from './Timer';
import Score from './Score';
import Pet from './Pet';

class Game extends Component {

	state = {
		score: 0,
		time: 30,
		end: false,
		first: Math.floor(Math.random()*9)
	}

	updateTime(time) {
		this.setState({ time: parseInt(time) });
	}

	score() {
		let score = this.state.score+1;
		this.setState({ score });
		this.props.updateScore(score);
	}

	end() {
		this.setState({ end: true });
		setTimeout(function() {
			this.props.progress();
		}.bind(this), 500);
	}

	render() {
		return(
			<Fragment>
				<Timer end={ () => this.end() } time={ 30 } updateTime={ (time) => this.updateTime(time) } />
				<Score end={ this.state.end } score={ this.state.score } />
				
				<Pet first={ this.state.first === 0 } end={ this.state.end } score={ () => this.score() } time={ this.state.time } col="left" row="top" />
				<Pet first={ this.state.first === 1 } end={ this.state.end } score={ () => this.score() } time={ this.state.time } col="center" row="top" />
				<Pet first={ this.state.first === 2 } end={ this.state.end } score={ () => this.score() } time={ this.state.time } col="right" row="top" />
				<Pet first={ this.state.first === 3 } end={ this.state.end } score={ () => this.score() } time={ this.state.time } col="left" row="middle" />
				<Pet first={ this.state.first === 4 } end={ this.state.end } score={ () => this.score() } time={ this.state.time } col="center" row="middle" />
				<Pet first={ this.state.first === 5 } end={ this.state.end } score={ () => this.score() } time={ this.state.time } col="right" row="middle" />
				<Pet first={ this.state.first === 6 } end={ this.state.end } score={ () => this.score() } time={ this.state.time } col="left" row="bottom" />
				<Pet first={ this.state.first === 7 } end={ this.state.end } score={ () => this.score() } time={ this.state.time } col="center" row="bottom" />
				<Pet first={ this.state.first === 8 } end={ this.state.end } score={ () => this.score() } time={ this.state.time } col="right" row="bottom" />
			</Fragment>
		);
	}
}

export default Game;