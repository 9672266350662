import React, { Component, Fragment } from 'react';

class WellDone extends Component {

	componentDidMount() {
		setTimeout(function() {
			document.querySelector('.well-done').classList.add('well-done--in');
			document.querySelector('.play-again').classList.add('play-again--in');
			//document.querySelector('.save').classList.add('save--in');
		}, 200);
	}

	playAgain() {
		document.querySelector('.well-done').classList.add('well-done--out');
		document.querySelector('.play-again').classList.add('play-again--out');
		//document.querySelector('.save').classList.add('save--out');

		setTimeout(function() {
			this.props.playAgain();
		}.bind(this), 500);
	}

	render() {
		return(
			<Fragment>
				<div className="well-done">
					<h2>Well done</h2>
					<h3>You fed <span>{ this.props.score }</span> pets!</h3>
					{/* <p>Save your score to see if you’ve made the leader board</p> */}
					<p>See if you can feed even more pets next time!</p>
				</div>
				{/* <button onClick={ () => this.props.progress() } className="button button--orange save">Save score</button> */}
				<button onClick={ () => this.playAgain() } className="button button--white play-again">Play again</button>
			</Fragment>
		);
	}
}

export default WellDone;