import React, { Component } from 'react';

class Score extends Component {
	render() {
		return(
			<div className={ `score-board ${this.props.end ? 'score-board--out' : ''}` }>
				<div className="score-board__label">Score:</div>
				<div className="score-board__score">{ this.props.score }</div>
			</div>
		);
	}
}

export default Score;