import React, { Component, Fragment } from 'react';
import anime from 'animejs';

class HowToPlay extends Component {

	countdown = React.createRef();

	state = {
		out: false,
		countdown: false,
		timer: 3
	}

	constructor(props) {
		super(props);
		this.hide = this.hide.bind(this);
		this.animateTimer = this.animateTimer.bind(this);
		this.reduceTime = this.reduceTime.bind(this);
	}

	componentDidMount() {
		setTimeout(function() {
			document.querySelector('.how-to-play').classList.add('how-to-play--in');
			document.querySelector('.how-to-play__start').classList.add('how-to-play__start--in');
		}, 150);
	}

	getReady() {
		if (!this.state.countdown) {
			this.setState({ countdown: true });
			document.querySelector('.how-to-play__start').classList.add('how-to-play__start--out');

			setTimeout(this.animateTimer, 500);
		}
	}

	animateTimer() {
		anime({
			targets: this.countdown.current,
			scale: 1.2,
			opacity: 0,
			loop: 4,
			direction: 'normal',
			easing: 'easeInOutCirc',
			loopComplete: this.reduceTime,
			complete: this.hide
		});
	}

	reduceTime() {
		let txt = this.state.timer;
		if (!isNaN(txt)) txt--;
		if (txt === 0) {
			txt = 'Go!';
			this.countdown.current.classList.add('how-to-play__count-down--go');
		}
		this.setState({ timer: txt });
	}

	close() {
		if (!this.props.out) {
			this.setState({ out: true }, function() {
				setTimeout(this.hide, 500);
				document.querySelector('.how-to-play').classList.add('how-to-play--out');
				document.querySelector('.how-to-play__start').classList.add('how-to-play__start--out');
			});
		}
	}

	hide() {
		this.props.progress();
	}

	render() {
		return(
			<Fragment>
				<div className="how-to-play">
				{ !this.state.countdown ?
					<Fragment>
						<h2>How to play</h2>
						<p>Tap the pets as they pop up to feed them.</p>
						<p>Feed as many as you can before the time runs out!</p>
					</Fragment>
				:
					<Fragment>
						<h2 className="how-to-play__get-ready">Get ready...</h2>
						<div ref={ this.countdown } className="how-to-play__count-down">{ this.state.timer }</div>
					</Fragment>
				}
				</div>
				<button className="button button--white how-to-play__start" onClick={ () => this.getReady() }>Play</button>
			</Fragment>
		);
	}
}

export default HowToPlay;