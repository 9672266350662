import React, { Component } from 'react';

class Intro extends Component {

	arrive = React.createRef();
	jump = React.createRef();

	state = {
		loop: false,
		out: false,
	}

	constructor(props) {
		super(props);
		this.hide = this.hide.bind(this);
	}

	startLoop() {
		this.setState({ loop: true });
		this.jump.current.play();
	}
	
	reset() {
		this.setState({ loop: false });
	}

	close() {
		if (!this.props.out) {
			this.jump.current.pause();
			if (this.arrive.current !== null) {
				this.arrive.current.pause();
				this.jump.current.parentNode.removeChild(this.jump.current);
			}

			this.setState({ out: true }, function() {
				document.querySelector('.intro').classList.add('intro--out');
				setTimeout(this.hide, 1000);
			});
		}
	}

	hide() {
		this.props.progress();
	}

	render() {
		return(
			<div className="intro">

				{ this.props.landscape ?
					<>
						{ !this.state.loop &&
							<video ref={ this.arrive } key="landscape-arrive" className="intro__video intro__video--arrive" muted playsInline autoPlay={ true } onEnded={ () => this.startLoop() }>
								<source src="video/arrive.landscape.mp4" type="video/mp4" />
							</video> 
						}

						<video ref={ this.jump } key="landscape-hop" className="intro__video intro__video--jump" muted autoPlay={ this.state.loop } playsInline loop>
							<source src="video/jump.landscape.mp4" type="video/mp4" />
						</video>
					</>
				:
					<>
						{ !this.state.loop &&
							<video ref={ this.arrive } key="arrive" className="intro__video intro__video--arrive" muted playsInline autoPlay={ true } onEnded={ () => this.startLoop() }>
								<source src="video/arrive.mp4" type="video/mp4" />
							</video> 
						}

						<video ref={ this.jump } key="hop" className="intro__video intro__video--jump" muted autoPlay={ this.state.loop } playsInline loop>
							<source src="video/jump.mp4" type="video/mp4" />
						</video>
					</>
				}

				<button className="button button--white intro__start" onClick={ () => this.close() }>Feed me!</button>
					
			</div>
		);
	}
}

export default Intro;