import React, { Component } from 'react';
//import anime from 'animejs';

class Pet extends Component {

	el = React.createRef();
	interval = false;
	hitInt = false;
	lifespan = false;
	entry = true;

	state = {
		hit: false,
		frame: 0,
		out: false,
		in: false,
		end: false
	}

	rows = ['top', 'middle', 'bottom'];
	cols = ['left', 'center', 'right'];
	pets = ['dog', 'cat', 'brown-rabbit', 'grey-rabbit'];

	constructor(props) {
		super(props);
		this.alt = this.alt.bind(this);
		this.hitAnim = this.hitAnim.bind(this);
		this.end = this.end.bind(this);
	}

	componentWillMount() {
		this.init();
	}

	componentDidMount() {
		this.show();
	}

	componentDidUpdate() {
		if (this.props.end && !this.state.end) {
			clearTimeout(this.lifespan);
			clearTimeout(this.interval);
			clearTimeout(this.entry);
			this.setState({ end: true });
			this.end();
		}
	}

	componentWillUnmount() {
		clearTimeout(this.interval);
	}

	init() {
		if (!this.props.end) {
			let pet = this.pets[Math.floor(Math.random()*(this.pets.length))];
			this.setState({ pet, hit: false, frame: 0, out: false, in: false });
		}
	}

	show() {
		let delay = Math.random() * (3 * (this.props.time/30)) + (this.props.time/30);
		if (this.entry) delay += Math.random()*5;
		if (this.props.first) delay = 0.25;

		if (!this.props.end) {
			this.entry = setTimeout(function() {
				this.interval = setTimeout(this.alt, 1000);
				this.setState({ in: true });
				this.lifespan = setTimeout(this.end, 2000 + Math.random()*1000 + ((this.props.time/30)*1500));
			}.bind(this), delay * 1000);
		}
	}

	alt() {
		let frame = this.state.frame === 1 ? 0 : 1,
			time = this.state.frame === 1 ? 1000 : 250;
		this.setState({ frame });
		this.interval = setTimeout(this.alt, time);
	}

	hit() {
		if (!this.state.out && !this.state.hit && this.state.in && !this.props.end) {
			clearTimeout(this.interval);
			this.setState({ hit: true });
			this.setState({ frame: 2 });
			this.hitInt = setInterval(this.hitAnim, 200);
			this.props.score();
		}
	}

	hitAnim() {
		let frame = this.state.frame + 1;
		this.setState({ frame: frame });
		if (frame === 4) {
			clearInterval(this.hitInt);
		}
	}

	end() {
		this.setState({ out: true, in: false });
		clearTimeout(this.interval);
		if (!this.props.end) {
			setTimeout(function() {
				this.reset();
			}.bind(this), 500);
		}

	}

	reset() {
		if (!this.props.end) {
			this.init();
			this.show();
		}
	}

	render() {
		return(
			<div ref={ this.el } className={ `pet pet--${this.state.pet} pet--${this.props.col} pet--${this.props.row} pet--${this.state.frame} ${this.state.in ? 'pet--in' : ''} ${this.state.hit ? 'pet--hit' : ''}`} onTouchStart={ () => this.hit() } onClick={ () => this.hit() }>
				
				{ this.state.pet === 'brown-rabbit' &&
					<svg xmlns="http://www.w3.org/2000/svg" width="281.53" height="225.78" viewBox="0 0 281.53 225.78"><path id="star-1" d="M274,25l5.18,11.61-9.46-8.22-3.68,13.37-1.71-12.7-9.06,4.47,7.56-6.8-7.57-6.27,8.38,4L261.16,12.5l9.09,7.83,9-10.21-4.8,11.43,10.69,4Z" transform="translate(-7.22 -10.12)"/><path id="star-2" d="M32.64,40.13,24.69,26.89,26.29,42,10.41,36.39,21.87,46.93l-14,8.45L23.15,50l1,11.91L26,50.83l10.16,10.8-1.47-14.5,16.43-1.85L36.38,42.09l3.44-13.43Z" transform="translate(-7.22 -10.12)"/><path id="star-3" d="M17.77,217.77,16,205.16l6.83,10.53,7.24-11.85L28.2,216.53l16.6-7L29,219.19l5.55,8.13-7-6.15-.92,12.18-6.57-10L8.6,230.63,16.39,221l-9.17-6.82Z" transform="translate(-7.22 -10.12)"/><path id="star-4" d="M55,119.24,51.3,109.13l-.92,10.57-10.1-6,6.43,8.73-15.16,1.38,15.62.92L46.25,133,49,125.67l5.51,8.72.92-10.1,11.48.92-9.64-4.14,4.13-8.73Z" transform="translate(-7.22 -10.12)"/><path id="star-5" d="M236.83,220.33l6.16-8.81L241.14,222l11.31-3.15L244,225.57l14.29,5.26-15.33-3.16-1.25,8.23-.76-7.82-7.59,7,1.73-10L223.74,223l10.39-1.49L232.4,212Z" transform="translate(-7.22 -10.12)"/><path id="star-6" d="M269.13,146.25l-9.25,13.22,2.77-15.67-17,4.73,12.71-10.15-12.71-7.54L260,135.23l1.88-12.34L263,134.61l11.38-10.51-2.6,15,17,3.13-15.59,2.24,2.6,14.25Z" transform="translate(-7.22 -10.12)"/></svg>
				}

				{ this.state.pet === 'grey-rabbit' &&
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 282.05 280.532"><g transform="translate(-2127.317 -698.411)"><path id="star-1" d="M2376.188,789.427l4.344-11.946,1.086,12.489,11.945-7.059-7.6,10.317,9.623,3.086-10.166-.371,1.086,9.774-3.258-8.688-6.515,10.317-1.086-11.946-13.574,1.086,11.4-4.887-4.887-10.317" transform="matrix(-0.719, 0.695, -0.695, -0.719, 4588.457, -360.439)"/><path id="star-2" d="M21.715,27.563,28.4,45.937l1.671-19.21L48.442,37.584,36.749,21.715,56.189,14.6l-20.275,2.94,1.67-15.033L32.572,15.869,22.551,0,20.88,18.375,0,16.7l17.539,7.517L10.021,40.09" transform="translate(2194.79 758.117) rotate(169)"/><path id="star-3" d="M2194.8,934.534l4.349-11.961,1.088,12.5,11.96-7.068-7.611,10.33,17.94,1.63-18.484,1.088,1.087,9.786-3.262-8.7-6.523,10.33-1.088-11.961-13.591,1.087,11.417-4.893-4.893-10.33Z" transform="matrix(0.883, -0.469, 0.469, 0.883, -231.215, 1095.343)"/><path id="star-4" d="M2174.295,852.647l-3.675-10.107-.919,10.565-10.106-5.972,6.432,8.727-15.159,1.379,15.618.918-.919,8.269,2.757-7.351,5.513,8.728.918-10.105,11.484.919-9.646-4.135,4.135-8.728Z" transform="translate(44 -46)"/><path id="star-5" d="M2396.273,870.638l3.674-10.106.92,10.565,10.105-5.972-6.432,8.728,15.16,1.379-15.619.918.919,8.27-2.757-7.351-5.512,8.727-.918-10.105-11.484.919,9.646-4.135-4.135-8.727Z" transform="matrix(0.966, 0.259, -0.259, 0.966, 294.838, -502.935)"/><path id="star-6" d="M11.943,10.107,15.617,0l.92,10.566L26.642,4.594l-6.431,8.728,9.483,2.662-9.942-.365.919,8.27-2.757-7.351L12.4,25.265l-.918-10.106L0,16.078l9.646-4.135L5.512,3.216" transform="matrix(-0.966, -0.259, 0.259, -0.966, 2399.829, 863.943)"/></g></svg>
				}

				{ this.state.pet === 'dog' &&
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 309.93 217.099"><g transform="translate(-2133.643 -753.819)"><path id="star-1" d="M2383.785,795.857l6.682-18.375,1.671,19.211,18.374-10.858L2398.819,801.7l14.8,4.747-15.637-.571,1.67,15.034-5.012-13.363-10.021,15.869-1.671-18.375-20.88,1.67,17.539-7.517-7.518-15.869" transform="matrix(-0.719, 0.695, -0.695, -0.719, 4682.527, -274.923)"/><path id="star-2" d="M2167.375,758.112l6.682-18.374,1.671,19.21,18.374-10.857-11.692,15.869,19.44,7.116-20.275-2.94,1.67,15.033-5.012-13.363-10.021,15.869L2166.54,767.3l-20.88,1.671,17.539-7.517-7.518-15.869" transform="translate(185.544 -381.534) rotate(11)"/><path id="star-3" d="M2202.377,940.948l6.682-18.375,1.671,19.211,18.374-10.858-11.692,15.869,27.562,2.5-28.4,1.671,1.67,15.034-5.012-13.363-10.021,15.869-1.671-18.375-20.88,1.67,17.539-7.517-7.518-15.869Z" transform="matrix(0.883, -0.469, 0.469, 0.883, -224.889, 1139.533)"/><path id="star-4" d="M2174.295,852.647l-3.675-10.107-.919,10.565-10.106-5.972,6.432,8.727-15.159,1.379,15.618.918-.919,8.269,2.757-7.351,5.513,8.728.918-10.105,11.484.919-9.646-4.135,4.135-8.728Z" transform="translate(20 -1)"/><path id="star-5" d="M2396.273,870.638l3.674-10.106.92,10.565,10.105-5.972-6.432,8.728,15.16,1.379-15.619.918.919,8.27-2.757-7.351-5.512,8.727-.918-10.105-11.484.919,9.646-4.135-4.135-8.727Z" transform="matrix(0.966, 0.259, -0.259, 0.966, 307.838, -521.935)"/></g></svg>
				}

				{ this.state.pet === 'cat' &&
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300.835 260.532"><g transform="translate(-2140.667 -728.411)"><path id="star-1" d="M2376.188,789.427l4.344-11.946,1.086,12.489,11.945-7.059-7.6,10.317,9.623,3.086-10.166-.371,1.086,9.774-3.258-8.688-6.515,10.317-1.086-11.946-13.574,1.086,11.4-4.887-4.887-10.317" transform="matrix(-0.719, 0.695, -0.695, -0.719, 4680.457, -330.439)"/><path id="star-2" d="M21.715,27.563,28.4,45.937l1.671-19.21L48.442,37.584,36.749,21.715,56.189,14.6l-20.275,2.94,1.67-15.033L32.572,15.869,22.551,0,20.88,18.375,0,16.7l17.539,7.517L10.021,40.09" transform="translate(2204.79 779.117) rotate(169)"/><path id="star-3" d="M2194.8,934.534l4.349-11.961,1.088,12.5,11.96-7.068-7.611,10.33,17.94,1.63-18.484,1.088,1.087,9.786-3.262-8.7-6.523,10.33-1.088-11.961-13.591,1.087,11.417-4.893-4.893-10.33Z" transform="matrix(0.883, -0.469, 0.469, 0.883, -211.215, 1148.343)"/><path id="star-4" d="M2174.295,852.647l-3.675-10.107-.919,10.565-10.106-5.972,6.432,8.727-15.159,1.379,15.618.918-.919,8.269,2.757-7.351,5.513,8.728.918-10.105,11.484.919-9.646-4.135,4.135-8.728Z" transform="translate(4 4)"/><path id="star-5" d="M2396.273,870.638l3.674-10.106.92,10.565,10.105-5.972-6.432,8.728,15.16,1.379-15.619.918.919,8.27-2.757-7.351-5.512,8.727-.918-10.105-11.484.919,9.646-4.135-4.135-8.727Z" transform="matrix(0.966, 0.259, -0.259, 0.966, 285.838, -492.935)"/><path id="star-6" d="M11.943,10.107,15.617,0l.92,10.566L26.642,4.594l-6.431,8.728,9.483,2.662-9.942-.365.919,8.27-2.757-7.351L12.4,25.265l-.918-10.106L0,16.078l9.646-4.135L5.512,3.216" transform="matrix(-0.966, -0.259, 0.259, -0.966, 2429.829, 955.943)"/></g></svg>
				}
			</div>
		);
	}
}

export default Pet;