import React, { Component } from 'react';

class SaveScore extends Component {

	state = {
		banned: []
	}

	kb = ['a', 'b', 'c', 'd', 'delete', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];

	componentDidMount() {
		if (!this.props.topTen) this.props.skip();
		else {
			fetch('/banned.json').then(response => {
				return response.json();
			}).then(data => {
				this.setState({ banned: data })
			}).catch(err => {
			});
		}
	}

	keyPress(key) {
		let name = this.props.name;
		if (key === 'delete') name = name.slice(0, -1);
		else if (name.length < 8) name += key.toUpperCase();

		/*if (this.state.banned.indexOf(name.toLowerCase()) > -1) {
			name = '';
		}

		if (name.length > 2) {
			for (let i=0; i<this.state.banned.length; i++) {
				if (name.toLowerCase().indexOf(this.state.banned[i]) > -1) {
					name = ''
					break;
				}
			}
		}*/

		this.props.updateName(name);
	}

	render() {
		return(
			<div className="save-score">
				<label>Enter your name</label>
				<div className="save-score__name">{ this.props.name }</div>

				{ this.kb.map(k => (
					<div key={ `key_${k}` } onTouchStart={ () => this.keyPress(k) } className={ `key ${k}`}>{k}</div>					
				))}

				<button onClick={ () => this.props.saveScore() } className="button button--white save-score__save">Done</button>
			</div>
		);
	}
}

export default SaveScore;