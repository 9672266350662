import React, { Component } from 'react';
//import seeThru from 'seethru';

class Leaderboard extends Component {

	state = {
		leaderboard: []
	}

	/* componentDidMount() {
		fetch('/leaderboard.json?cache='+Math.random()*1000).then(response => {
			return response.json();
		}).then(data => {
			data.sort((a, b) => parseFloat(b.score) - parseFloat(a.score));
			this.setState({ leaderboard: data }, function() {

				if (this.props.score >= data[0].score) {
					var transparentVideo = seeThru.create('#fireworks', {start: 'external'}).ready(function(instance, video, canvas) {
						instance.play();
						video.addEventListener('ended', function () {
							instance.revert();
							setTimeout(function() {
								const elem = document.getElementById('fireworks');
								elem.parentNode.removeChild(elem);
							}, 500);
						});
					});
				}
			})
		}).catch(err => {
		});
	} */

	render() {
		const leaderboard = this.state.leaderboard.slice(0, 10);//this.state.leaderboard.slice(0, 10);

		let result = '', video = '';
		if (leaderboard.length > 0) {
			result = 'You haven’t made the board this time, play again!';
			if (this.props.score >= leaderboard[0].score) {
				result = 'Congratulations! You’re our highest scorer!';
				video = <video width="1080" height="3840" id="fireworks"><source src="/video/fireworks.mp4" type="video/mp4" /></video>;
			} else if ((leaderboard.length < 10 || this.props.score > leaderboard[9].score) && this.props.score < leaderboard[0].score) {
				result = 'Well done! You’re a high scorer!';
			}
		}

		return(
			<div className="leaderboard">
				<h2>High scores</h2>
				<h3>{ result }</h3>
				<div className="leaderboard__entries">
				{ leaderboard.map((entry, i) => (
					<div key={ `score_${i}` } className={ `leaderboard__entry ${this.props.name === entry.name && this.props.score === entry.score ? 'leaderboard__entry--mine' : ''}` }>{ i+1 }. { entry.name } <span>{ entry.score }</span></div>
				))}
				</div>
				{ video }
				<button className="button button--white leaderboard__play-again" onClick={ () => this.props.playAgain() }>Play again</button>
			</div>
		);
	}
}

export default Leaderboard;