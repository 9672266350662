import React, { Component } from 'react';

class Timer extends Component {

	circle = React.createRef();
	circ = 2 * Math.PI * 95.5;
	timer = false;

	state = {
		time: this.props.time
	}

	constructor(props) {
		super(props);
		this.tick = this.tick.bind(this);
	}

	componentDidMount() {
		this.timer = setInterval(this.tick, 1000);
		this.circle.current.style.strokeDasharray = this.circ;
	}

	tick() {
		this.props.updateTime(this.state.time-1);
		this.setState({ time: this.state.time-1 }, function() {
			if (this.state.time === 0) {
				clearInterval(this.timer);
				this.props.end();
			}
			const progress = this.state.time / this.props.time;
			const dashoffset = this.circ * (1 - progress);
			this.circle.current.style.strokeDashoffset = dashoffset;
		})
	}

	render() {
		return(
			<div className={ `timer ${this.state.time === 0 ? 'timer--out' : ''}` }>
				<div className="timer__time">{ this.state.time }</div>
				<svg className="timer__progress" width="207" height="207" viewBox="0 0 207 207">
					<circle className="timer__back" cx="103.5" cy="103.5" r="95.5" strokeWidth="16" />
					<circle ref={ this.circle } className="timer__value" cx="103.5" cy="103.5" r="95.5" strokeWidth="16" />
				</svg>
			</div>
		);
	}
}

export default Timer;